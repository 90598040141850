import React from 'react';

export default function DataCircle({ ...props}) {
  return (
    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
        <div style={{borderColor: props.borderColor, height: props.circleSize, width: props.circleSize, borderRadius: "100%", borderWidth: props.borderWidth, display: "flex", justifyContent: "center", alignItems: "center", alignContent: "center"}}>
            <h3 style={{fontSize: "24px"}}>{Math.round(props.percentage).toString()}{props.isPercentage ? "%" : ""}</h3> 
        </div>
        <h4 style={{fontSize: "16px", marginTop: "15px", textTransform: "capitalize"}}>{props.title}</h4>
    </div>
  );
}
