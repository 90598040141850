import React from 'react';

export default function DataPlate({ ...props}) {
  return (
    <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "1em"}}>
        <div style={{background: props.background, height: props.plateHeight, width: props.plateWidth,
           borderRadius: props.borderRadius, borderWidth: props.borderWidth, display: "flex",
            justifyContent: "space-between", alignItems: "center", alignContent: "center", padding: "10px 20px"}}>
            <h4 style={{fontSize: "20px", marginTop: "5px", textTransform: "capitalize"}}>{props.title}</h4>
            <h3 style={{fontSize: "24px"}}>{Math.round(props.percentage).toString()}</h3> 
        </div>
    </div>
  );
}
