import React, {useContext} from 'react';
import {store} from '../../../../Store'
import { useHistory } from 'react-router-dom';
import './Header.css'

import nydlogo from '../../../../assets/nydlogo.png';



export default function Header({ ...props}) {
  const {state, dispatch} = useContext(store);
  const history = useHistory();

  console.log(props)

  const logout = () => {
    dispatch({
      type: "LOGOUT"
    });
    dispatch({
      type: "SET_DATA",
      payload: {
        isAuthenticated: false
      }
    })
    localStorage.removeItem('user');
    // history.replace('/admin');
  }

  return (
    <div className="header">
        <div className="logo">
            <img src={nydlogo}></img>
            <h5 style={{fontFamily: "NotoSerif!important"}}>Statistics</h5>
        </div>
        <div>
            {props.logout == false ? <></> : <h3 style={{fontFamily: "NotoSerif!important", fontSize: "24px", color: "#7B7B7B", textDecoration: "underline", cursor: "pointer"}} onClick={() => logout()}>Odjava</h3>}
        </div>
    </div>
  );
}
