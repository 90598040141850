import React from "react";
import vprasalnik from "../../../../assets/panel/vprasalnik.svg";
import moment from "moment";
import axios from "axios"

export default function ActivityTable({ ...props }) {
  const [popUp, setPopUp] = React.useState(false)
  const [cancelationReason, setCancelationReason] = React.useState(false)
  const data = props.data;
  const [selectedSession, setSeletedSession] = React.useState()

  const openLink = (link) => {
    window.open(link);
  }

  console.log( "test", props)



  const updateSession = (selectedSession) => {
    console.log('Update survey with survey id', selectedSession);
    axios.patch(`${process.env.REACT_APP_API_URL}sessions/${selectedSession._id}`, {
      id: selectedSession._id,
      rating: selectedSession.rating,
      ratingOptions: selectedSession.ratingOptions,
      comment: selectedSession.drugo,
      willingToPay: selectedSession.willingToPay,
      pacientId: selectedSession.pacientId,
      doctorId: selectedSession.doctorId,
      firstName: selectedSession.firstName,
      lastName: selectedSession.lastName,
      email: selectedSession.email,
      birthday: selectedSession.birthday,
      gender: selectedSession.gender,
      cancelationInitiator: "Doctor",
      callStatus: "Canceled",
      cancelationExplenation: cancelationReason
    }, {
      headers: {
        'x-api-key': `${process.env.REACT_APP_API_LOCAL_KEY}`
      }
    })
  }

  const renderPopUp = (selectedSession) => {
    return (
      <>
       <div className="test"></div>
       <div className="popUp">
        <h3>Zakaj želite preklicati ta video posvet?</h3>
        <textarea
        onChange={(e) => {setCancelationReason(e.target.value)
        console.log(cancelationReason)}}
        placeholder="Napišite zakaj želite preklicati ta video posvet....">
        </textarea>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "flex-end",
            margin: "2em 0",
          }}
        >
          <div className="button-grey" style={{ marginRight: "1em" }} onClick={() => setPopUp(false)}>
            Prekliči
          </div>
          <div className="button-red" style={{ marginTop: "0" }} onClick={() => {
            setPopUp(false);
            updateSession(selectedSession);
            props.props.history.replace('pregled');
            }}>
            Oddajte
          </div>
        </div>
      </div>
      </>
    );
  };


  
  return (
    <>
    {console.log(props)}
    {console.log(selectedSession)}
      <div className="nyd-activityTable">

      {props.limited ? props.data.slice(0, 10).map((data, i) => {
        const currentDate = data.user.sessionDate.substr(0, 10);
        currentDate.substr(0, 10);
        return (
                <div className="nyd-activityTable-item-analytics">
                {popUp ? renderPopUp(selectedSession) : <></>}
                <div><b>{currentDate}</b> - {moment(data.user.sessionStartTime).hours()}:{moment(data.user.sessionStartTime).minutes()} - {moment(data.user.sessionStartTime).add(15, 'minutes').hours()}:{moment(data.user.sessionStartTime).add(15, 'minutes').minutes()}</div>
                <div>
                  <strong>Pacient: {data.user.firstName == undefined ? "N/A" : `${data.user.firstName} ${data.user.lastName}`}</strong>
                </div>
                <div>
                  <strong>Zdravnik: {data.doctor.firstName} {data.doctor.lastName}</strong>
                </div>
                <div className="nyd-activityTable-options-analytics">
                  <div className="nyd-activityTable-options option">
                    <img src={vprasalnik} onClick={() => props.props.history.replace('pacientcard', {'pacient': data.user, 'doctor': data.doctor, 'editable': props.editable} )}></img>
                  </div>
                </div>
              </div>
      )}
      ) : props.data.map((data, i) => {
        const currentDate = data.user.sessionDate.substr(0, 10);
        currentDate.substr(0, 10);
        return (
                <div className="nyd-activityTable-item-analytics">
                {popUp ? renderPopUp(selectedSession) : <></>}
                <div><b>{currentDate}</b> - {moment(data.user.sessionStartTime).hours()}:{moment(data.user.sessionStartTime).minutes()} - {moment(data.user.sessionStartTime).add(15, 'minutes').hours()}:{moment(data.user.sessionStartTime).add(15, 'minutes').minutes()}</div>
                <div>
                  <strong>Pacient: {data.user.firstName == undefined ? "N/A" : `${data.user.firstName} ${data.user.lastName}`}</strong>
                </div>
                <div>
                  <strong>Zdravnik: {data.doctor.firstName} {data.doctor.lastName}</strong>
                </div>
                <div className="nyd-activityTable-options-analytics">
                  <div className="nyd-activityTable-options option">
                    <img src={vprasalnik} onClick={() => props.props.history.replace('pacientcard', {'pacient': data.user, 'doctor': data.doctor, 'editable': props.editable} )}></img>
                  </div>
                </div>
              </div>
      )}
      )}
      </div>
    </>
  );
}
