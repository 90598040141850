import React, {useContext, useEffect, useState} from "react";
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from "react-router-dom";
import nemateAktivnosti from "../../assets/panel/nemateAktivnosti.svg";
import ActivityTable from "./partials/activityTable/ActivityTable";
import axios from "axios"
import moment from "moment"
import {store} from '../../Store';
import enlargeScreen from "../../assets/enlargeScreen.svg"

import SideBar from './partials/sidebar/SideBar';
import Header from './partials/header/Header'
import Overview from "./Overview"
import PacientCard from "./PacientCard"
import Pacients from "./Pacients";
import Settings from "./Settings";
import RatingsTable from "./partials/ratingsTable/RatingsTable";
import MiniCalendar from "./partials/miniCalendar/MiniCalendar"

export default function App({ ...props}) {
  const { state, dispatch } = useContext(store);
  const history = useHistory();
  const [dataView, setDataView] = React.useState('prihodnji');

  console.log(state)

  const [nextWeekSessions, setNextWeekSessions] = useState([]);
  const [todaysSessions, setTodaysSessions] = useState([]);
  const [dayBeforeSessions, setDayBeforeSessions] = useState([]);
  const [monthAgoSessions, setMonthAgoSessions] = useState([]);
  const [analyticData, setAnalyticData] = useState({});
  const [todaysSessionsThatPassed, setTodaysSessionsThatPassed] = useState([]);
  const [calendarData, setCalendarData] = useState([])

  const currentDateEndThAgo = moment().subtract(30, "minutes").toISOString();

  const currentDateEnd = moment().set({hour:25,minute:0,second:0,millisecond:0}).toISOString();
  const currentDateStart = moment().set({hour:3,minute:0,second:0,millisecond:0}).toISOString();

  const dayBeforeCurrentDateStart = moment().subtract(1, 'day').set({hour:3,minute:0,second:0,millisecond:0}).toISOString();
  const twoDaysAhead = moment().add(3, 'day').set({hour:1,minute:0,second:0,millisecond:0}).toISOString();
  const startOfMonth = moment().clone().startOf('year').subtract(11, 'year').set({hour:3,minute:0,second:0,millisecond:0}).toISOString();
  const currentTime = new Date().toISOString();


  console.table("CurrentDateStart", currentDateStart, currentDateEndThAgo)
  const getUser = () => {
    const user = localStorage.getItem('user');
    console.log(user)
    const userJson = JSON.parse(user);
    console.log(userJson)
    if (user === null) {
      return false;
    }
    return userJson;
  };


  useEffect(async () => {
    let rememberedUser = getUser();
    console.log("USER ",rememberedUser);

    if (rememberedUser !== false) {
      dispatch({
        type: "SET_DATA",
        payload: {
          isAuthenticated: true,
          user: rememberedUser.user,
          accessToken: rememberedUser.access_token
        }
      })
      history.replace('pregled')
    }

    console.log("Should happen before render", state.data.isAuthenticated)

    await getAnalyticData();
    await getCalendarData();
    setNextWeekSessions(await getSessions(currentDateEnd, twoDaysAhead, 3));
    setTodaysSessions(await getSessions(currentDateEndThAgo, currentDateEnd, 2));
    setTodaysSessionsThatPassed(await getSessions(currentDateStart, currentDateEndThAgo, 3));
    setDayBeforeSessions(await getSessions(dayBeforeCurrentDateStart, currentDateStart, 3));
    setMonthAgoSessions(await getSessions(startOfMonth, dayBeforeCurrentDateStart, 1000));
  }, [])


   const getSessions = async (startDate, endDate, limit ) => {
    return axios.get(`${process.env.REACT_APP_API_URL}sessions/analyticsessions/${startDate}/${endDate}/${limit}/${process.env.REACT_APP_PROJECT}`, {
      headers: {
        'x-api-key': `${process.env.REACT_APP_API_LOCAL_KEY}`
      }
    }).then((response) => {
      console.table(startDate, endDate, response.data)
       return response.data;
    });
  }

  const getAnalyticData = async () => {
    return axios.get(`${process.env.REACT_APP_API_URL}sessions/averageData/${process.env.REACT_APP_PROJECT}`,
    {
      headers: {
        'x-api-key': `${process.env.REACT_APP_API_LOCAL_KEY}`
      }
    }).then((response) => {
      console.table("Analytic Data",response.data)
      setAnalyticData(response.data);
    });
  }

  const getCalendarData = async () => {
    return axios.get(`${process.env.REACT_APP_API_URL}availabilities/analytics`,
    {
      headers: {
        'x-api-key': `${process.env.REACT_APP_API_LOCAL_KEY}`
      }
    }).then((response) => {
      console.table("Analytic Data",response.data)
      setCalendarData(response.data);
    });
  }


  const renderNoActivity = () => {
    return (
      <>
        <div className="section-header">
          <h3>Aktivnosti</h3>
          <div className="button-grey" onClick={() => props.history.replace('nastavitve')}>Nastavitve prostih terminov</div>
        </div>
        <hr />
        <div
          className="section-content"
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
            backgroundImage: `url('${nemateAktivnosti}')`,
            backgroundPosition: "right",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div>
            <h5 style={{ fontSize: "22px", lineHeight: "35px" }}>
              Še nemate nobenih aktivnosti.<br></br>
              Povabite pacienta na posvet.
            </h5>
            <div className="button-red">Povabi</div>
          </div>
        </div>
      </>
    );
  };


  console.log("SLEDECI DAN MSD", nextWeekSessions)
  console.log("SLEDECI DAN MSD", todaysSessions)
  console.log("SLEDECI DAN MSD", dayBeforeSessions)
  console.log("SLEDECI DAN MSD", monthAgoSessions)
  console.log("SLEDECI DAN MSD", nextWeekSessions)

  const renderUpcomingEvents = () => {
    return (
      <>
        <h5>
          <strong>Danes</strong> - {moment(currentDateStart).format('dddd, D. MMMM, YYYY')}
        </h5>
        <div className="grey-container">
          <ActivityTable props={props} editable={false} data={todaysSessions}/>
        </div>
        <h5>
          <strong>Naslednji teden</strong>
        </h5>
        <div className="grey-container">
          <ActivityTable props={props} editable={false} data={nextWeekSessions}/>
        </div>
      </>
    );
  };

  const renderPastEvents = () => {
    return (
      <>
        <h5>
          <strong>Včeraj</strong> - {moment(dayBeforeCurrentDateStart).format('dddd, D. MMMM, YYYY')}
        </h5>
        <div className="grey-container">
          <ActivityTable props={props} editable={false} data={dayBeforeSessions} call={true} />
        </div>
        <h5>
          <strong>Prejšnji</strong> - od {moment(startOfMonth).format('YYYY')} do danes
        </h5>
        <div className="grey-container">
          <ActivityTable props={props} editable={false} data={monthAgoSessions} call={true}/>
        </div>
      </>
    );
  };

  const renderRatingTable = () => {
    return (
      <>
           <RatingsTable props={props} editable={false} data={analyticData.doctors} call={true}  limiter={3} />
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end"}}><h3 style={{color: "#5CC8BD", fontSize: "18px", margin: "1em", cursor: "pointer"}}
      onClick={() => props.history.replace('ocenezdravnika')}
      >Poglej več</h3></div>
      </>
    )
  }

  const renderActivity = () => {
    return (
      <>
        <div
          className="section-header"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignContent: "normal",
            alignItems: "normal",
          }}
        >
                    {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h4 style={{fontSize:"22px", color: "#575757", fontWeight: "bold"}}>Tedenski koledar zdravnikov</h4>
            </div>
            <div className="button-grey" onClick={() => props.history.replace('nastavitve')}>Nastavitve prostih terminov</div>
          </div> */}

          {/* <div className="grey-container">
            <h3 style={{textTransform: "capitalize", fontSize: "32px"}}>{moment().format("MMMM, YYYY")}</h3>
            <MiniCalendar props={props}  data={calendarData} />
        </div> */}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h4 style={{fontSize:"22px", color: "#575757", fontWeight: "bold"}}>Posveti</h4>
            </div>
            {/* <div className="button-grey" onClick={() => props.history.replace('nastavitve')}>Nastavitve prostih terminov</div> */}
          </div>
          <div
            className="filters"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <h5 onClick={() => setDataView('prihodnji')} style={(dataView == "prihodnji") ? {color: "#C4385B", textDecoration: "underline"} : {}}>Prihodnji</h5>
            <h5 onClick={() => setDataView('pretekli')} style={(dataView == "pretekli") ? {color: "#C4385B", textDecoration: "underline"} : {}}>Pretekli</h5>
          </div>
        </div>
        <hr />
        <div
          className="section-content"
          style={{ height: "auto", color: "#707070" }}
        >
          {(dataView == "prihodnji") ? renderUpcomingEvents() : renderPastEvents()}
        </div>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end"}}><h3 style={{color: "#5CC8BD", fontSize: "18px", margin: "1em", cursor: "pointer"}}
        onClick={() => props.history.replace('pacienti')}
        >Poglej več</h3></div>
                <hr style={{ margin: "3em 0" }} />
                <h5 style={{ fontSize: "22px"}}>
        </h5>
        <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h4 style={{fontSize:"22px", color: "#575757", fontWeight: "bold"}}>Seznam zdravnikov in njihovih ocen</h4>
            </div>
            <div className="button-grey" onClick={() => props.history.replace('analitika', {data: analyticData})}>Ocene posvetov</div>
          </div>
       {renderRatingTable()}

      </>
    );
  };

 

  if(state.data.isAuthenticated) {
    return (
      <div className="doctor-panel-resize-app">
        <div className="resize-window">
            <div className="resize-window-header">
              <img src={enlargeScreen} ></img>
              <h3>Spremenite velikost okna!</h3>
            </div>
            <div className="resize-window-description">
              <p>
              Za prikaz vsebine na naši spletni strani vas prosimo, da povečate velikost okna spletnega brskalnika.</p>
            </div>
          </div> 
      <div className="doctor-panel">
        {console.log("State inside of admin panel", state)}
      <Header />
      <div className="analytics-section">
      <div className="sivo"></div>
      <div className="section-container">
      <div
          className="section-header"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignContent: "normal",
            alignItems: "normal",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h3>Dobrodošli</h3>
            </div>
          </div>
          <hr style={{ margin: "1em 0", borderTopWidth: "2px" }} />
          <div className="quick-analytics">
            <div className="item">
            <div className="item-one">
                      <h1>Število posvetov</h1>
                      {console.log("SESION MONTH AGO", monthAgoSessions)}
              <h3>{(analyticData.general == undefined) ? "N/A" :monthAgoSessions.length}</h3>
            </div>
            </div>
            <div className="item">
            <div className="item-two">
              <h1>Povprečno trajanje posveta</h1>
              <h3>{(!analyticData.general) ? "N/A" : "20 min"}</h3>
            </div>
            </div>
            <div className="item">
            <div className="item-three">
              <h1>Napotenih zavarovancev</h1>
              <h3>{(!analyticData.general || !analyticData.general[0].userNextStep) ? "N/A" : Math.round(analyticData.general[0].userNextStep?.nextStep)}%</h3>
            </div>
            </div>
          </div>
        </div>
        {renderActivity()}
        </div>
    </div>
      </div>
      </div>
    );
  } else {
    return(
      <Redirect to="/login"></Redirect>
    )
  }
  
}
