import React from "react";
import vprasalnik from "../../../../assets/panel/vprasalnik.svg";
import moment from "moment";
import axios from "axios"

export default function RatingsTable({ ...props }) {
  const [popUp, setPopUp] = React.useState(false)
  const [cancelationReason, setCancelationReason] = React.useState(false)
  const data = props.data;
  const [selectedSession, setSeletedSession] = React.useState()

  const openLink = (link) => {
    window.open(link);
  }

  console.log( "test", props)



  const updateSession = (selectedSession) => {
    console.log('Update survey with survey id', selectedSession);
    axios.patch(`${process.env.REACT_APP_API_URL}sessions/${selectedSession._id}`, {
      id: selectedSession._id,
      rating: selectedSession.rating,
      ratingOptions: selectedSession.ratingOptions,
      comment: selectedSession.drugo,
      willingToPay: selectedSession.willingToPay,
      pacientId: selectedSession.pacientId,
      doctorId: selectedSession.doctorId,
      firstName: selectedSession.firstName,
      lastName: selectedSession.lastName,
      email: selectedSession.email,
      birthday: selectedSession.birthday,
      gender: selectedSession.gender,
      cancelationInitiator: "Doctor",
      callStatus: "Canceled",
      cancelationExplenation: cancelationReason
    }, {
      headers: {
        'x-api-key': `${process.env.REACT_APP_API_LOCAL_KEY}`
      }
    })
  }

  const renderPopUp = (selectedSession) => {
    return (
      <>
       <div className="test"></div>
       <div className="popUp">
        <h3>Zakaj želite preklicati ta video posvet?</h3>
        <textarea
        onChange={(e) => {setCancelationReason(e.target.value)
        console.log(cancelationReason)}}
        placeholder="Napišite zakaj želite preklicati ta video posvet....">
        </textarea>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "flex-end",
            margin: "2em 0",
          }}
        >
          <div className="button-grey" style={{ marginRight: "1em" }} onClick={() => setPopUp(false)}>
            Prekliči
          </div>
          <div className="button-red" style={{ marginTop: "0" }} onClick={() => {
            setPopUp(false);
            updateSession(selectedSession);
            props.props.history.replace('pregled');
            }}>
            Oddajte
          </div>
        </div>
      </div>
      </>
    );
  };


  
  return (
    <>
    {console.log(props, data)}
      <div className="nyd-activityTable">
      <div className="nyd-activityTable-item-analytics" style={{gridTemplateColumns: "repeat(auto-fill, minmax(30%, 3fr))"}}>
                  <div >
                  <strong>Doktor</strong>
                  </div>
                  <div >
                  <strong>Število posvetov</strong>
                  </div>
                  <div >
                  <strong>Povprečna ocena</strong>
                  </div>
              </div>
      {data == undefined  ? <></> : 
      (props.limiter != undefined) ? 
      data.slice(0, props.limiter).map((data, i) => {
        return (
                <div className="nyd-activityTable-item-analytics" style={{gridTemplateColumns: "repeat(auto-fill, minmax(30%, 3fr))"}}>
                  <div >
                  <strong>{data.session.firstName} {data.session.lastName}</strong>
                  </div>
                  <div >
                  <strong>{data.sessionCount}</strong>
                  </div>
                  <div >
                  <strong style={{textTransform: "capitalize"}}>{data.doctorRating}</strong>
                  </div>
              </div>
      )}
      ) : 
      data.map((data, i) => {
        return (
                <div className="nyd-activityTable-item-analytics" style={{gridTemplateColumns: "repeat(auto-fill, minmax(30%, 3fr))"}}>
                  <div >
                  <strong>{data.session.firstName} {data.session.lastName}</strong>
                  </div>
                  <div >
                  <strong>{data.sessionCount}</strong>
                  </div>
                  <div >
                  <strong style={{textTransform: "capitalize"}}>{data.doctorRating}</strong>
                  </div>
              </div>
      )}
      )     
      }
      </div>
    </>
  );
}
