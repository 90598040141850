import React, {useState, useContext} from 'react';
import Header from './partials/header/Header';
import '../../App.css'
import axios from 'axios';
import {store} from '../../Store';

import drMerkur from '../../assets/panel/drMerkur.png'
import enlargeScreen from "../../assets/enlargeScreen.svg"

export default function Login({ ...props}) {

const [username, setUsername] = useState("");
const [password, setPassword] = useState("");
const [errorMessage, setErrorMessage] = useState("")
const {state, dispatch} = useContext(store);

  const login = async () => {
    axios.post(`${process.env.REACT_APP_API_URL}auth/login`, {
    username: username,
    password: password
    }).then(response => {
      if(response.data.user.doctorType == "Administrator"){
        console.log("Response",response)
        dispatch({
          type: "SET_DATA",
          payload: {
            isAuthenticated: (response.data.user == undefined) ? false : true,
            user: response.data.user,
            accessToken: response.data.access_token
          }
        })
        const loggedUser = {
          user: response.data.user,
          accessToken: response.data.access_token,
          isAuthenticated: (response.data.user == undefined) ? false : true
        };
        localStorage.setItem('user', JSON.stringify(loggedUser));
        console.log("State before update",state);
        loggedUser.isAuthenticated ? props.history.replace("/pregled") : props.history.replace("/login")  
      } else {
        setErrorMessage('Uporabnik nima pravica za dostop!')
      }
    }).catch(function (error) {
      // handle error
      console.log(error);
      props.history.replace("/login")
    }) 
  }

  return (
    <>
          <div className="doctor-panel-resize-app">
        <div className="resize-window">
            <div className="resize-window-header">
              <img src={enlargeScreen} ></img>
              <h3>Spremenite velikost okna!</h3>
            </div>
            <div className="resize-window-description">
              <p>
              Za prikaz vsebine na naši spletni strani vas prosimo, da povečate velikost okna spletnega brskalnika.</p>
            </div>
          </div> 
    <Header logout={false} />
    {console.log("State after update",state)}
    <div className="login">
      <div className="login-image-analytics">
        <h1>
        Vaš partner<br/>za zdravje <br/>na daljavo
        </h1>
      </div>
      <div className="login-form">
        <div className="login-inputs">
          <img src={drMerkur}></img>
          <h2><strong>Prijava</strong></h2>
          <div className="login-form-fields">
            <label>
            Uporabniško ime
            </label>
            <input placeholder="" type="email"  onChange={(e) => setUsername(e.target.value)}/>
            <label>
              Vaše geslo
            </label>
            <input placeholder="" type="password" onChange={(e) => setPassword(e.target.value)}/>
          </div>
          <div style={{cursor: "pointer"}} className="button" onClick={() => login()}>Prijavite se</div>
          <h3 style={{color: "#C4385B"}}>{errorMessage}</h3>

          {/* <h5 style={{textAlign: "right", color: "#5CC8BD", fontSize: "20px"}}>Pozabljeno geslo?</h5> */}
        </div>
        <div className="login-footer">
          <p>Splošni pogoji poslovanja</p>
          <p>Politika zasebnosti</p>
          <p>C 2020 NYD d.o.o. Vse pravice pridržane.</p>
        </div>
      </div>
    </div>
    </div>
    </>
  );
}
