import React, { useState, useEffect } from "react";
import {
  MonthlyBody,
  MonthlyCalendar,
  MonthlyNav,
  DefaultMonthlyEventItem,
} from "@zach.codes/react-calendar";

import { endOfMonth, startOfMonth, subHours } from "date-fns";
import "@zach.codes/react-calendar/dist/calendar-tailwind.css";
import { CalendarNav } from "./CalendarNav";
import axios from "axios";

import "../../../../App.css";
import repeatable from "../../../../assets/panel/repeatable.svg";
import trashCan from "../../../../assets/panel/trashCan.svg";
import plusIcon from "../../../../assets/panel/plusIcon.svg";

import Select from "react-select";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
require("moment/locale/sl.js");

export default function CustomCalendar({ ...props }) {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [sessions, setSessions] = useState([]);
  const [availability, setAvailabilty] = useState();
  const [selectedStartTime, setSelectedStartTime] = useState("7:00");
  const [selectedEndTime, setSelectedEndTime] = useState("23:15");

  const [options, setOptions] = useState(false);
  const [selectedDay, setSelectedDay] = useState();
  const [singleDayPopUp, setSingleDayPopUp] = useState();
  const [multiDayPopUp, setmultiDayPopUp] = useState();
  const [oneDayPopUp, setOneDayPopUp] = useState();
  const [value, onChange] = useState(new Date());

  const [selectedDayAvailability, setSelectedDayAvailability] = useState();

  const [dayInWeek, setDayInWeek] = useState();

  const [state, setState] = useState({
    numberofTimePickers: [1],
    selectedtime: [{ index: 0, startTime: "07:00", endTime: "07:00" }],
  });

  const { numberofTimePickers, selectedtime } = state;

  const startTime = [
    { value: "09:15", label: "09:15" },
    { value: "10:15", label: "10:15" },
    { value: "12:15", label: "12:15" },
  ];

  const endTime = [
    { value: "09:15", label: "09:15" },
    { value: "10:15", label: "10:15" },
    { value: "12:15", label: "12:15" },
  ];
  const getCalendarData = async () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}availabilities/doctor/${props.user}`
      )
      .then((response) => {
        const sessions = [];

        response.data.map((data) => {
          const startHour = new Date(data.startTime).getHours().toString();
          const startMinutes = new Date(data.startTime).getMinutes().toString();

          const endHour = new Date(data.endTime).getHours().toString();
          const endMinutes = new Date(data.endTime).getMinutes().toString();

          console.log(
            "",
            startHour,
            startMinutes,
            endHour,
            endMinutes,
            startHour.toString()
          );

          sessions.push({
            id: data._id,
            date: new Date(data.date),
            title: `${startHour.length <= 1 ? "0" + startHour : startHour}:${
              startMinutes.length <= 1 ? startMinutes + "0" : startMinutes
            } - ${endHour}:${
              endMinutes.length <= 1 ? endMinutes + "0" : endMinutes
            } `,
            availabilityId: data.availability,
          });
        });
        setSessions(sessions);
        console.log("THIS WE GET", sessions, response.data);

        axios
          .get(
            `${process.env.REACT_APP_API_URL}availabilities/single/${props.user}`
          )
          .then((response) => {
            setAvailabilty(response.data[0]);
            //console.log("THIS WE AVAILABILITY GET", response.data);
          });
      });
  };

  useEffect(() => {
    getCalendarData();
  }, []);

  const addDayToExceptions = (id, selectedDay) => {
    console.log(
      "DATES TO BE PUSHED AS EXCEPTION",
      new Date(selectedDay).toISOString(),
      new Date(selectedDay)
    );



    axios
    .put(
      `${process.env.REACT_APP_API_URL}availabilities/${id}`,
      {
        id: id,
        exceptions: [selectedDay],
      }
    )
    .then((response) => {
      //console.log(response)
      getCalendarData();
    });
  };

  const getDaysArray = (start, end) => {
    for (
      var arr = [], dt = new Date(start);
      dt <= end;
      dt.setDate(dt.getDate() + 1)
    ) {
      dt.setUTCHours(0, 0, 0, 0);
      arr.push(`${new Date(dt).toISOString()}`);
    }
    return arr;
  };

  const addOneDay = (id, selectedDay) => {
    console.log(
      "DATES TO BE PUSHED AS EXCEPTION",
      new Date(selectedDay).toISOString(),
      new Date(selectedDay)
    );

    const fileteredCurrentDateIds = sessions.filter((item) => new Date(item.date).getDate() === new Date(selectedDay).getDate());
    console.log("ID za jedan dan koji smo obiljezili", fileteredCurrentDateIds)

    fileteredCurrentDateIds.map((item) => addDayToExceptions(item.availabilityId, selectedDay))

    var type = "single";
    // addDayToExceptions(id, selectedDay);
    createNewAvailabilityWithoutRepeatableDays(selectedDay, selectedDay, type);
    getCalendarData();


    axios
    .put(
      `${process.env.REACT_APP_API_URL}availabilities/${id}`,
      {
        id: id,
        exceptions: [selectedDay],
      }
    )
    .then((response) => {
      //console.log(response)
    });
  };

  const adjustForTimezone = (date) => {
    // debugger;
    var date2 = new Date(date.getTime());
    var timeOffsetInMS = date.getTimezoneOffset() * 60000;
    date2.setTime(date.getTime() - timeOffsetInMS);
    return date2;
  };

  const addMoreDaysWithinMonth = (id, selectedDay) => {
    const monthStart = adjustForTimezone(new Date(value[0]));
    const monthEnd = adjustForTimezone(new Date(value[1]));
    const daysInMonth = getDaysArray(monthStart, monthEnd);

    const daysToExceptions = daysInMonth.filter(
      (item) => new Date(item).getDay() == dayInWeek
    );
    daysToExceptions.map((item) => availability.exceptions.push(item));

    console.log("Month start dates", monthStart, monthEnd);
    console.log("Dates in month", daysInMonth);
    console.log("Exception dates", daysToExceptions);
    // console.log("Exception dates pushed to exception array", availability.exceptions);

    const sessionsToFilter = [];

    sessions.map((data) => {
      console.log("KAj imava v data", data);
      sessionsToFilter.push({
        id: data.availabilityId,
        date: new Date(data.date).toISOString(),
        title: `${new Date(data.startTime).getHours()}:${new Date(
          data.startTime
        ).getMinutes()} - ${new Date(data.endTime).getHours()}:${new Date(
          data.endTime
        ).getMinutes()} `,
        availabilityId: data.availabilityId,
      });
    });

    const dateWhereWeAddSingleExceptions = sessionsToFilter.filter((item) =>
      daysInMonth.includes(new Date(item.date).toISOString())
    );

    console.log(
      "dateWhereWeAddSingleExceptions",
      sessionsToFilter,
      daysInMonth,
      dateWhereWeAddSingleExceptions
    );

    dateWhereWeAddSingleExceptions.map((item) => {
      console.log(
        "I UPDATED",
        item.availabilityId,
        "with following dates",
        item.date,
        new Date(item.date).toISOString()
      );
      console.log(
        "I UPDATED",
        availability.exceptions.push(item.date),
        availability.exceptions,
        item
      );

      availability.exceptions.push(item.date);

      axios
        .put(`${process.env.REACT_APP_API_URL}availabilities/${id}`, {
          id: item.availabilityId,
          exceptions: daysInMonth,
        })
        .then((response) => {
          //console.log(response)
        });
    });

    // axios
    //   .put(`${process.env.REACT_APP_API_URL}availabilities/${id}`, {
    //     id: id,
    //     exceptions: availability ? availability.exceptions : [],
    //   })
    //   .then((response) => {
    //     //console.log(response)
    //   });
    createNewMultipleDaysAvailability(monthStart, monthEnd);
    getCalendarData();
  };

  const addOneDayForWholeMonth = (id, selectedDay) => {
    console.log(
      "DATES TO BE PUSHED AS EXCEPTION",
      new Date(selectedDay).toISOString(),
      new Date(selectedDay)
    );

    const monthStart = adjustForTimezone(startOfMonth(new Date()));
    const monthEnd = adjustForTimezone(endOfMonth(new Date()));
    const dayInWeek = selectedDay.getDay();
    const daysInMonth = getDaysArray(monthStart, monthEnd);

    const daysToExceptions = daysInMonth.filter(
      (item) => new Date(item).getDay() == dayInWeek
    );
    daysToExceptions.map((item) => availability.exceptions.push(item));

    console.log("dayInWeek", selectedDay, selectedDay.getDay());
    console.log("Month start dates", monthStart, monthEnd);
    console.log("Dates in month", daysInMonth);
    console.log("Exception dates", daysToExceptions);
    console.log(
      "Exception dates pushed to exception array",
      availability.exceptions
    );

    const sessionsToFilter = [];


    sessions.map((data) => {
      console.log("KAj imava v data", data);
      sessionsToFilter.push({
        id: data.availabilityId,
        date: new Date(data.date).toISOString(),
        title: `${new Date(data.startTime).getHours()}:${new Date(
          data.startTime
        ).getMinutes()} - ${new Date(data.endTime).getHours()}:${new Date(
          data.endTime
        ).getMinutes()} `,
        availabilityId: data.availabilityId,
      });
    });

    const dateWhereWeAddSingleExceptions = sessionsToFilter.filter((item) =>
      daysToExceptions.includes(new Date(item.date).toISOString())
    );

    console.log(
      "dateWhereWeAddSingleExceptions",
      sessionsToFilter,
      daysToExceptions,
      dateWhereWeAddSingleExceptions
    );

    const exceptions = [];

    

    dateWhereWeAddSingleExceptions.map((item) => {
      console.log(
        "I UPDATED",
        item.availabilityId,
        "with following dates",
        item.date,
        new Date(item.date).toISOString()
      );
      console.log(
        "I UPDATED",
        availability.exceptions.push(item.date),
        availability.exceptions,
        item
      );

      let currentAvailabilityException = exceptions.filter(
        (exception) => exception.availabilityId == item.availabilityId
      )[0];

      if (currentAvailabilityException) {
        currentAvailabilityException.exceptions.push(item.date);
      } else {
        exceptions.push({
          availabilityId: item.availabilityId,
          exceptions: [item.date],
        });
      }
    });

    console.log("Custom object", exceptions);

    axios
      .post(`${process.env.REACT_APP_API_URL}availabilities/addexceptions`, {
        availabilityExceptions: exceptions,
      })
      .then((response) => {
        console.log("Success update of exceptions", response);
      });

    createNewAvailability(monthStart, monthEnd, dayInWeek);
    getCalendarData();
  };

  const createNewMultipleDaysAvailability = (monthStart, monthEnd) => {
    console.log("MONTH START MONTH END", monthStart, monthEnd);

    const startHour = selectedStartTime.split(":");
    const endHour = selectedEndTime.split(":");
    const repeatableDays = [0, 1, 2, 3, 4, 5, 6];

    const startTimeHour = new Date().setHours(startHour[0], startHour[1]);
    const endTimeHour = new Date().setHours(endHour[0], endHour[1]);

    console.log(
      "New availability",
      selectedStartTime,
      selectedEndTime,
      startTimeHour,
      endTimeHour
    );

    selectedtime.map((item) => {
      axios
        .post(`${process.env.REACT_APP_API_URL}availabilities`, {
          doctor: props.user,
          endTime: moment()
            .set("hour", item.endTime.substr(0, 2))
            .set("minute", item.endTime.substr(3, 5))
            .set("millisecond", 0)
            .toISOString(),
          startTime: moment()
            .set("hour", item.startTime.substr(0, 2))
            .set("minute", item.startTime.substr(3, 5))
            .set("millisecond", 0)
            .toISOString(),
          type: "repeatable",
          repeatableFrom: monthStart,
          repeatableTo: monthEnd,
          repeatableDays: repeatableDays,
          exceptions: [],
        })
        .then((response) => {
          console.log("New availability", response, selectedEndTime);
          getCalendarData();
          setSingleDayPopUp(false);
          setmultiDayPopUp(false);
          setOneDayPopUp(false);
        });
    });
  };

  const createNewAvailability = (monthStart, monthEnd, dayInWeek) => {
    console.log("MONTH START MONTH END", monthStart, monthEnd);

    const startHour = selectedStartTime.split(":");
    const endHour = selectedEndTime.split(":");
    const repeatableDays = [];
    repeatableDays.push(dayInWeek);

    const startTimeHour = new Date().setHours(startHour[0], startHour[1]);
    const endTimeHour = new Date().setHours(endHour[0], endHour[1]);

    console.log(
      "New availability",
      selectedStartTime,
      selectedEndTime,
      startTimeHour,
      endTimeHour
    );

    selectedtime.map((item) => {
      axios
        .post(`${process.env.REACT_APP_API_URL}availabilities`, {
          doctor: props.user,
          endTime: moment()
            .set("hour", item.endTime.substr(0, 2))
            .set("minute", item.endTime.substr(3, 5))
            .set("millisecond", 0)
            .toISOString(),
          startTime: moment()
            .set("hour", item.startTime.substr(0, 2))
            .set("minute", item.startTime.substr(3, 5))
            .set("millisecond", 0)
            .toISOString(),
          type: "repeatable",
          repeatableFrom: monthStart,
          repeatableTo: monthEnd,
          repeatableDays: repeatableDays,
          exceptions: [],
        })
        .then((response) => {
          console.log("New availability", response, selectedEndTime);
          getCalendarData();
          setSingleDayPopUp(false);
          setmultiDayPopUp(false);
          setOneDayPopUp(false);
        });
    });
  };

  const createNewAvailabilityWithoutRepeatableDays = (
    monthStart,
    monthEnd,
    type
  ) => {
    const startHour = selectedStartTime.split(":");
    const endHour = selectedEndTime.split(":");
    const repeatableDays = [];
    repeatableDays.push(dayInWeek);

    const startTimeHour = new Date().setHours(startHour[0], startHour[1]);
    const endTimeHour = new Date().setHours(endHour[0], endHour[1]);

    console.log(
      "New availability",
      selectedStartTime,
      selectedEndTime,
      startTimeHour,
      endTimeHour
    );

    selectedtime.map((item) => {
      console.log(
        "REPEAT FOR EVERY TIME RTANGE",
        item,
        item.startTime.substr(3, 5)
      );

      axios
        .post(`${process.env.REACT_APP_API_URL}availabilities`, {
          doctor: props.user,
          endTime: moment()
            .set("hour", item.endTime.substr(0, 2))
            .set("minute", item.endTime.substr(3, 5))
            .set("millisecond", 0)
            .toISOString(),
          startTime: moment()
            .set("hour", item.startTime.substr(0, 2))
            .set("minute", item.startTime.substr(3, 5))
            .set("millisecond", 0)
            .toISOString(),
          type: type === "single" || type == undefined ? "single" : repeatable,
          repeatableFrom: new Date(monthStart).toISOString(),
          repeatableTo: monthEnd,
          exceptions: [],
          repeatableDays: [],
        })
        .then((response) => {
          console.log("New availability", response, selectedEndTime);
          getCalendarData();
          setSingleDayPopUp(false);
          setmultiDayPopUp(false);
          setOneDayPopUp(false);
        });
    });
  };

  const createSlots = (
    date,
    configSlotHours,
    configSlotMinutes,
    configSlotPreparation,
    timeArr
  ) => {
    //console.log('Time we need to trim', availability);

    // Getting values from slotConfig using destructuring
    //  const {configSlotHours,configSlotMinutes,configSlotPreparation,timeArr} = slotConfig;

    // This is the default date that we are using to make use of javascript date functions
    // slotsArray will hold final slots
    // _timeArrStartTime is used to store start time date object from the timeArr
    // _timeArrEndTime is used to store end time date object from the timeArr
    // _tempSlotStartTime is used to create slots by adding config values and check that the time is less than the end time and lies withing the duration specified
    // _startSlot holds value of start date time of slot
    // _endSlot holds value of end date time of slot

    let defaultDate = new Date(date).toISOString().substring(0, 10);
    let slotsArray = [];
    let _timeArrStartTime;
    let _timeArrEndTime;
    let _tempSlotStartTime;
    let _endSlot;
    let _startSlot;

    //console.log("DEFAULT DATE", defaultDate)
    // Loop over timeArr
    for (var i = 0; i < timeArr.length; i++) {
      // Creating time stamp using time from timeArr and default date
      _timeArrStartTime = new Date(
        defaultDate + " " + timeArr[i].startTime
      ).getTime();
      _timeArrEndTime = new Date(
        defaultDate + " " + timeArr[i].endTime
      ).getTime();
      _tempSlotStartTime = _timeArrStartTime;

      // Loop around till _tempSlotStartTime is less end time from timeArr

      while (
        new Date(_tempSlotStartTime).getTime() <
        new Date(_timeArrEndTime).getTime()
      ) {
        _endSlot = new Date(_tempSlotStartTime);
        _startSlot = new Date(_tempSlotStartTime);

        //Adding minutes and hours from config to create slot and overiding the value of _tempSlotStartTime
        _tempSlotStartTime = _endSlot.setHours(
          parseInt(_endSlot.getHours()) + parseInt(configSlotHours)
        );
        _tempSlotStartTime = _endSlot.setMinutes(
          parseInt(_endSlot.getMinutes()) + parseInt(configSlotMinutes)
        );

        // Check _tempSlotStartTime is less than end time after adding minutes and hours, if true push into slotsArr
        if (
          new Date(_tempSlotStartTime).getTime() <=
          new Date(_timeArrEndTime).getTime()
        ) {
          // DateTime object is converted to time with the help of javascript functions
          // If you want 24 hour format you can pass hour12 false
          slotsArray.push({
            startTime: `${new Date(_startSlot).getHours()}:${new Date(
              _startSlot
            ).getMinutes()}`,
          });
        }

        //preparation time is added in last to maintain the break period
        _tempSlotStartTime = _endSlot.setMinutes(
          _endSlot.getMinutes() + parseInt(configSlotPreparation)
        );
      }
    }

    return slotsArray;
  };

  console.log(
    "TIME SLOTS FOR TODAY",
    createSlots(
      "2021-04-14T09:15:00.000Z",
      "0",
      "15",
      "0",
      [
        {
          startTime: "07:00",
          endTime: "22:15",
        },
      ],
      availability
    )
  );
  //UI

  const renderPicker = (i) => {
    return (
      <div className="timePicker">
        <div style={{ display: "flex", flexDirection: "row", flex: 2 }}>
          <select
            style={{ flex: 1 }}
            default="startTime"
            // value={}
            onChange={(e) => {
              console.log(e.target.value);
              const time = { index: i, startTime: e.target.value };
              const objectsToPush = [];
              const object = selectedtime.map((item) => {
                if (item.index == i) {
                  objectsToPush.push({
                    index: i,
                    startTime: e.target.value,
                    endTime: item.endTime,
                  });
                } else {
                  objectsToPush.push(item);
                }
              });
              setState({ ...state, selectedtime: objectsToPush });
            }}
          >
           <option value="07:00" id="07:00">
              07:00
            </option>
            <option value="07:20" id="07:20">
              07:20
            </option>
            <option value="07:40" id="07:40">
              07:40
            </option>
            <option value="08:00" id="08:00">
              08:00
            </option>
            <option value="08:20" id="08:20">
              08:20
            </option>
            <option value="08:40" id="08:40">
              08:40
            </option>
            <option value="09:00" id="09:00">
              09:00
            </option>
            <option value="09:20" id="09:20">
              09:20
            </option>
            <option value="09:40" id="09:40">
              09:40
            </option>
            <option value="10:00" id="10:00">
              10:00
            </option>
            <option value="10:20" id="10:20">
              10:20
            </option>
            <option value="10:40" id="10:40">
              10:40
            </option>
            <option value="11:00" id="11:00">
              11:00
            </option>
            <option value="11:20" id="11:20">
              11:20
            </option>
            <option value="11:40" id="11:40">
              11:40
            </option>
            <option value="12:00" id="12:00">
              12:00
            </option>
            <option value="12:20" id="12:20">
              12:20
            </option>
            <option value="12:40" id="12:40">
              12:40
            </option>
            <option value="13:00" id="13:00">
              13:00
            </option>
            <option value="13:20" id="13:20">
              13:20
            </option>
            <option value="13:40" id="13:40">
              13:40
            </option>
            <option value="14:00" id="14:00">
              14:00
            </option>
            <option value="14:20" id="14:20">
              14:20
            </option>
            <option value="14:40" id="14:40">
              14:40
            </option>
            <option value="15:00" id="15:00">
              15:00
            </option>
            <option value="15:20" id="15:20">
              15:20
            </option>
            <option value="15:40" id="15:40">
              15:40
            </option>
            <option value="16:00" id="16:00">
              16:00
            </option>
            <option value="16:20" id="16:20">
              16:20
            </option>
            <option value="16:40" id="16:40">
              16:40
            </option>
            <option value="17:00" id="17:00">
              17:00
            </option>
            <option value="17:20" id="17:20">
              17:20
            </option>
            <option value="17:40" id="17:40">
              17:40
            </option>
            <option value="18:00" id="18:00">
              18:00
            </option>
            <option value="18:20" id="18:20">
              18:20
            </option>
            <option value="18:40" id="18:40">
              18:40
            </option>
            <option value="19:00" id="19:00">
              19:00
            </option>
            <option value="19:20" id="19:20">
              19:20
            </option>
            <option value="19:40" id="19:40">
              19:40
            </option>
            <option value="20:00" id="20:00">
              20:00
            </option>
            <option value="20:20" id="20:20">
              20:20
            </option>
            <option value="20:40" id="20:40">
              20:40
            </option>
            <option value="21:00" id="21:00">
              21:00
            </option>
            <option value="21:20" id="21:20">
              21:20
            </option>
            <option value="21:40" id="21:40">
              21:40
            </option>
            <option value="22:00" id="22:00">
              22:00
            </option>
          </select>
          <select
            style={{ flex: 1 }}
            // value={selectedtime[i].endTime}
            onChange={(e) => {
              const time = { index: i, endTime: e.target.value };
              const objectsToPush = [];
              const object = selectedtime.map((item) => {
                if (item.index == i) {
                  objectsToPush.push({
                    index: i,
                    startTime: item.startTime,
                    endTime: e.target.value,
                  });
                } else {
                  objectsToPush.push(item);
                }
              });
              setState({ ...state, selectedtime: objectsToPush });
            }}
          >
            <option value="07:00" id="07:00">
              07:00
            </option>
            <option value="07:20" id="07:20">
              07:20
            </option>
            <option value="07:40" id="07:40">
              07:40
            </option>
            <option value="08:00" id="08:00">
              08:00
            </option>
            <option value="08:20" id="08:20">
              08:20
            </option>
            <option value="08:40" id="08:40">
              08:40
            </option>
            <option value="09:00" id="09:00">
              09:00
            </option>
            <option value="09:20" id="09:20">
              09:20
            </option>
            <option value="09:40" id="09:40">
              09:40
            </option>
            <option value="10:00" id="10:00">
              10:00
            </option>
            <option value="10:20" id="10:20">
              10:20
            </option>
            <option value="10:40" id="10:40">
              10:40
            </option>
            <option value="11:00" id="11:00">
              11:00
            </option>
            <option value="11:20" id="11:20">
              11:20
            </option>
            <option value="11:40" id="11:40">
              11:40
            </option>
            <option value="12:00" id="12:00">
              12:00
            </option>
            <option value="12:20" id="12:20">
              12:20
            </option>
            <option value="12:40" id="12:40">
              12:40
            </option>
            <option value="13:00" id="13:00">
              13:00
            </option>
            <option value="13:20" id="13:20">
              13:20
            </option>
            <option value="13:40" id="13:40">
              13:40
            </option>
            <option value="14:00" id="14:00">
              14:00
            </option>
            <option value="14:20" id="14:20">
              14:20
            </option>
            <option value="14:40" id="14:40">
              14:40
            </option>
            <option value="15:00" id="15:00">
              15:00
            </option>
            <option value="15:20" id="15:20">
              15:20
            </option>
            <option value="15:40" id="15:40">
              15:40
            </option>
            <option value="16:00" id="16:00">
              16:00
            </option>
            <option value="16:20" id="16:20">
              16:20
            </option>
            <option value="16:40" id="16:40">
              16:40
            </option>
            <option value="17:00" id="17:00">
              17:00
            </option>
            <option value="17:20" id="17:20">
              17:20
            </option>
            <option value="17:40" id="17:40">
              17:40
            </option>
            <option value="18:00" id="18:00">
              18:00
            </option>
            <option value="18:20" id="18:20">
              18:20
            </option>
            <option value="18:40" id="18:40">
              18:40
            </option>
            <option value="19:00" id="19:00">
              19:00
            </option>
            <option value="19:20" id="19:20">
              19:20
            </option>
            <option value="19:40" id="19:40">
              19:40
            </option>
            <option value="20:00" id="20:00">
              20:00
            </option>
            <option value="20:20" id="20:20">
              20:20
            </option>
            <option value="20:40" id="20:40">
              20:40
            </option>
            <option value="21:00" id="21:00">
              21:00
            </option>
            <option value="21:20" id="21:20">
              21:20
            </option>
            <option value="21:40" id="21:40">
              21:40
            </option>
            <option value="22:00" id="22:00">
              22:00
            </option>
          </select>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            justifyContent: "space-around",
          }}
        >
          {selectedtime.length == 1 ? (
                      <div style={{ width: "14px", height: "15px" }}></div>

          ) : i == selectedtime.length - 1 ? (
            <img
              src={trashCan}
              onClick={() => {
                const time = selectedtime.slice(-1);
                const tickers = numberofTimePickers.pop();
                setState({ ...state, selectedtime: time }, { ...state, numberofTimePickers: tickers });
              }}
            ></img>
          ) : (
            <div style={{ width: "14px", height: "15px" }}></div>
          )}
          <img
            src={plusIcon}
            onClick={() => {
              const objects = numberofTimePickers;
              objects.push("1");
              setState({ ...state, numberofTimePickers: objects });
              const ranges = selectedtime;
              ranges.push({
                index: i + 1,
                startTime: "09:15",
                endTime: "09:15",
              });
              setState({ ...state, selectedtime: ranges });
            }}
          ></img>
        </div>
      </div>
    );
  };

  const oneDayPopUpContent = (availabilityId, date, dayInWeek) => {
    return (
      <div className="singleDayPopUp">
        <h4 style={{ textAlign: "center" }}>
          Uredi razpoložljivost za: "
          {moment(new Date(selectedDay).toDateString())
            .locale("sl")
            .format("DD-MM-YYYY")
            .toUpperCase()}
          "
        </h4>
        <h5>Kdaj ste na voljo?</h5>
        <>
          {numberofTimePickers.map((picker, i) => renderPicker(i))}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignContent: "center",
              alignItems: "center",
              margin: "2em 0 0 0",
            }}
          >
            <div
              className="button-red"
              style={{
                background: "#fefefe",
                color: "#000",
                borderColor: "transparent",
                marginRight: "2em",
              }}
              onClick={() => setOneDayPopUp(false)}
            >
              Prekliči
            </div>
            <div
              className="button-red"
              onClick={() => addOneDay(selectedDayAvailability, selectedDay)}
            >
              Oddajte
            </div>
          </div>
        </>
      </div>
    );
  };

  const singleDayPopUpContent = (availabilityId, date, dayInWeek) => {
    return (
      <div className="singleDayPopUp">
        {console.log(new Date(selectedDay).getDay())}
        <h4 style={{ textAlign: "center" }}>
          Uredi razpoložljivost: "
          {moment(new Date(selectedDay).toDateString())
            .locale("sl")
            .format("dddd")
            .toUpperCase()}
          "
        </h4>
        <h5>Kdaj ste na voljo?</h5>
        <>
          {numberofTimePickers.map((picker, i) => renderPicker(i))}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignContent: "center",
              alignItems: "center",
              margin: "2em 0 0 0",
            }}
          >
            <div
              className="button-red"
              style={{
                background: "#fefefe",
                color: "#000",
                borderColor: "transparent",
                marginRight: "2em",
              }}
              onClick={() => setSingleDayPopUp(false)}
            >
              Prekliči
            </div>
            <div
              className="button-red"
              onClick={() =>
                addOneDayForWholeMonth(
                  selectedDayAvailability,
                  selectedDay,
                  dayInWeek
                )
              }
            >
              Oddajte
            </div>
          </div>
        </>
      </div>
    );
  };

  const multipleDaysPopUp = () => {
    return (
      <div className="singleDayPopUp">
        <h5>Kdaj ste na voljo?</h5>
        <Calendar
          style={{ margin: "2em 0" }}
          onChange={onChange}
          value={value}
          selectRange={true}
        />
        <>
          {numberofTimePickers.map((picker, i) => renderPicker(i))}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignContent: "center",
              alignItems: "center",
              margin: "2em 0 0 0",
            }}
          >
            <div
              className="button-red"
              style={{
                background: "#fefefe",
                color: "#000",
                borderColor: "transparent",
                marginRight: "2em",
              }}
              onClick={() => setmultiDayPopUp(false)}
            >
              Prekliči
            </div>
            <div
              className="button-red"
              onClick={() =>
                addMoreDaysWithinMonth(
                  selectedDayAvailability,
                  selectedDay,
                  dayInWeek
                )
              }
            >
              Oddajte
            </div>
          </div>
        </>
      </div>
    );
  };

  const openOptions = (date, id) => {
    setSelectedDay(date);
  };

  const openSingleDay = (date, id) => {
    resetState();
    setOptions(false);
    setSingleDayPopUp(true);
  };

  const openMultiDay = (date, id) => {
    resetState();
    setOptions(false);
    setmultiDayPopUp(true);
  };

  const openOneDay = (date, id) => {
    resetState();
    setOptions(false);
    setOneDayPopUp(true);
  };

  const slotovi = createSlots(
    "2021-04-14T09:15:00.000Z",
    "0",
    "20",
    "0",
    [
      {
        startTime: "07:00",
        endTime: "22:20",
      },
    ],
    availability
  );

  const resetState = (state) => {
    const defaultTime = [{ index: 0, startTime: "07:00", endTime: "07:00" }];
    const objects = [];
    objects.push("1");
    setState({ selectedtime: defaultTime, numberofTimePickers: objects });
  };

  return (
    <div id="CAllY">
      {console.log("DATUM OD DO", value)}
      {console.log(selectedDay)}
      {console.log("STATE", state)}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flex: 1,
          marginBottom: "10px",
        }}
      >
        <h3 className="heading-text">Dosegljivost</h3>
        <div
          className="button-red"
          onClick={() => {
            openMultiDay();
            console.log("Odpri za vec datumov");
          }}
        >
          <h6>Uredi več datumov</h6>
          {/* <div>
            {" "}
            {slotovi.map((item) => (
              <option value={item.startTime} id={item.startTime}>
                {item.startTime}
              </option>
            ))}
          </div> */}
        </div>
      </div>
      <MonthlyCalendar
        style={{ maxHeight: "20vh" }}
        currentMonth={currentMonth}
        onCurrentMonthChange={(date) => setCurrentMonth(date)}
      >
        <CalendarNav />
        {multiDayPopUp ? multipleDaysPopUp() : <></>}
        <MonthlyBody
          
          events={sessions}
          renderDay={(data) =>
            data.map((day) => (
              <div className="eventHolder">
                {oneDayPopUp ? oneDayPopUpContent(day.availabilityId) : <></>}

                <p>{day.title}</p>
                <img
                  style={{ margin: "10px 0", cursor: "pointer" }}
                  src={repeatable}
                  onClick={() => {
                    setSelectedDay(day.date);
                    setSelectedDayAvailability(day.availabilityId);
                    setOptions(true);
                    console.log("Changed statte", state);
                  }}
                ></img>
                {options ? (
                  selectedDay == day.date ? (
                    <div className="optionsPopOver">
                      <div>
                        <h5
                          style={{
                            marginTop: "0",
                            textAlign: "center",
                            fontFamily: "NotoSerif",
                          }}
                        >
                          Uredi razpoložljivost:
                        </h5>{" "}
                      </div>
                      <div
                      style={{cursor: "pointer"}}
                        onClick={() => {
                          openSingleDay();
                          setSelectedDayAvailability(day.availabilityId);
                        }}
                      >
                        <h6>
                          1. Uredite vse{" "}
                          <span style={{ textTransform: "capitalize" }}>
                            {" "}
                            {moment(new Date(day.date).toDateString())
                              .locale("sl")
                              .format("dddd")}
                          </span>
                        </h6>
                      </div>
                      <div
                      style={{cursor: "pointer"}}
                        onClick={() => {
                          openOneDay();
                          setSelectedDayAvailability(day.availabilityId);
                        }}
                      >
                        <h6>2. Uredi datum</h6>
                      </div>
                      <div
                      style={{cursor: "pointer"}}
                        onClick={() => {
                          addDayToExceptions(day.availabilityId, day.date);
                          setSelectedDayAvailability(day.availabilityId);
                        }}
                      >
                        <h6>3. Ni na voljo</h6>
                      </div>
                      <div>
                        {" "}
                        <button
                        style={{cursor: "pointer"}}
                          className="button-red"
                          style={{ width: "100%", height: "55px" }}
                          onClick={() => {
                            setOptions(false);
                          }}
                        >
                          {" "}
                          Prekliči
                        </button>{" "}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
                {singleDayPopUp ? (
                  singleDayPopUpContent(
                    day.availabilityId,
                    day.date,
                    new Date(day.date).getDay()
                  )
                ) : (
                  <></>
                )}
              </div>
            ))
          }
        />
      </MonthlyCalendar>
    </div>
  );
}
