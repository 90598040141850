import React, { useState, useEffect, useContext } from "react";
import nemateAktivnosti from "../../assets/panel/nemateAktivnosti.svg";
import ActivityTable from "./partials/activityTable/ActivityTable";
import axios from "axios"
import moment from "moment"
import {store} from '../../Store';
import Header from './partials/header/Header'
import backButton from "../../assets/panel/backButton.svg";
import RatingsTable from "./partials/ratingsTable/RatingsTable";
export default function DoctorRatings({ ...props }) {
  const [dataView, setDataView] = React.useState('prihodnji');
  const {state, dispatch} = useContext(store);

  console.log(state)

  const [nextWeekSessions, setNextWeekSessions] = useState([]);
  const [todaysSessions, setTodaysSessions] = useState([]);
  const [dayBeforeSessions, setDayBeforeSessions] = useState([]);
  const [monthAgoSessions, setMonthAgoSessions] = useState([]);
  const [todaysSessionsThatPassed, setTodaysSessionsThatPassed] = useState([]);
  const [analyticData, setAnalyticData] = useState({});

  const currentDateEndThAgo = moment().subtract(30, "minutes").toISOString();

  const currentDateEnd = moment().set({hour:25,minute:0,second:0,millisecond:0}).toISOString();
  const currentDateStart = moment().set({hour:3,minute:0,second:0,millisecond:0}).toISOString();

  const dayBeforeCurrentDateStart = moment().subtract(1, 'day').set({hour:3,minute:0,second:0,millisecond:0}).toISOString();
  const twoDaysAhead = moment().add(3, 'day').set({hour:1,minute:0,second:0,millisecond:0}).toISOString();
  const startOfMonth = moment().clone().startOf('year').subtract(11, 'year').set({hour:3,minute:0,second:0,millisecond:0}).toISOString();
  const currentTime = new Date().toISOString();


  console.table("CurrentDateStart", currentDateStart, currentDateEndThAgo)

  useEffect(async () => {
    await getAnalyticData();
    setNextWeekSessions(await getSessions(state.data.user._id, currentDateEnd, twoDaysAhead, 100));
    setTodaysSessions(await getSessions(state.data.user._id, currentDateEndThAgo, currentDateEnd, 100));
    setTodaysSessionsThatPassed(await getSessions(state.data.user._id, currentDateStart, currentDateEndThAgo, 100));
    setDayBeforeSessions(await getSessions(state.data.user._id, dayBeforeCurrentDateStart, currentDateStart, 100));
    setMonthAgoSessions(await getSessions(state.data.user._id, startOfMonth, dayBeforeCurrentDateStart, 100));
  }, [])


   const getSessions = async (doctorId, startDate, endDate, limit) => {
    return axios.get(`${process.env.REACT_APP_API_URL}sessions/analyticsessions/${startDate}/${endDate}/${limit}/${process.env.REACT_APP_PROJECT}`, {
      headers: {
        'x-api-key': `${process.env.REACT_APP_API_LOCAL_KEY}`
      }
    }).then((response) => {
      console.table(startDate, endDate, response.data)
       return response.data;
    });
  }
  

  const getAnalyticData = async () => {
    return axios.get(`${process.env.REACT_APP_API_URL}sessions/averageData/${process.env.REACT_APP_PROJECT}`, {
      headers: {
        'x-api-key': `${process.env.REACT_APP_API_LOCAL_KEY}`
      }
    }).then((response) => {
      console.table("Analytic Data",response.data)
      setAnalyticData(response.data);
    });
  }


  const renderNoActivity = () => {
    return (
      <>
        <div className="section-header">
          <h3>Aktivnosti</h3>
          <div className="button-grey" onClick={() => props.history.replace('nastavitve')}>Nastavitve prostih terminov</div>
        </div>
        <hr />
        <div
          className="section-content"
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
            backgroundImage: `url('${nemateAktivnosti}')`,
            backgroundPosition: "right",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div>
            <h5 style={{ fontSize: "22px", lineHeight: "35px" }}>
              Še nemate nobenih aktivnosti.<br></br>
              Povabite pacienta na posvet.
            </h5>
            <div className="button-red">Povabi</div>
          </div>
        </div>
      </>
    );
  };


  const renderUpcomingEvents = () => {
    return (
      <>
        <h5>
          <strong>Danes</strong> - {moment(currentDateStart).format('dddd, D. MMMM, YYYY')}
        </h5>
        <div className="grey-container">
          <ActivityTable props={props} editable={false} data={todaysSessions}/>
        </div>
        <hr style={{ margin: "3em 0" }} />
        <h5>
          <strong>Naslednji teden</strong>
        </h5>
        <div className="grey-container" style={{
            marginBottom: "2em"}}>
          <ActivityTable props={props} editable={false} data={nextWeekSessions}/>
        </div>
      </>
    );
  };

  const renderPastEvents = () => {
    return (
      <>
        <h5>
          <strong>Včeraj</strong> - {moment(dayBeforeCurrentDateStart).format('dddd, D. MMMM, YYYY')}
        </h5>
        <div className="grey-container">
          <ActivityTable props={props} editable={false} data={dayBeforeSessions} call={true} />
        </div>
        <hr style={{ margin: "3em 0" }} />
        <h5>
          <strong>Prejšnji</strong> - od {moment(startOfMonth).format('YYYY')} do danes
        </h5>
        <div className="grey-container"         style={{
            marginBottom: "2em"}}>
          <ActivityTable props={props} editable={false} data={monthAgoSessions} call={true}/>
        </div>
      </>
    );
  };

  const renderRatingTable = () => {
    return (
      <>
           <RatingsTable props={props} editable={false} data={analyticData.doctors} call={true} />
      </>
    )
  }

  const renderActivity = () => {
    return (
      <>
        <div
          className="section-header"
          style={{
            marginBottom: "2em",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignContent: "normal",
            alignItems: "normal",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h4 style={{fontSize:"34px", color: "#575757", fontWeight: "bold", marginBottom: "1em"}}>Vsi posveti</h4>
            </div>
            {/* <div className="button-grey" onClick={() => props.history.replace('nastavitve')}>Nastavitve prostih terminov</div> */}
          </div>
          <div
            className="filters"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <h5 onClick={() => setDataView('prihodnji')} style={(dataView == "prihodnji") ? {color: "#C4385B", textDecoration: "underline"} : {}}>Prihodnji</h5>
            <h5 onClick={() => setDataView('pretekli')} style={(dataView == "pretekli") ? {color: "#C4385B", textDecoration: "underline"} : {}}>Pretekli</h5>
          </div>
        </div>
        <hr />
        <div
          className="section-content"
          style={{ height: "auto", color: "#707070" }}
        >
          {(dataView == "prihodnji") ? renderUpcomingEvents() : renderPastEvents()}
        </div>
      </>
    );
  };

  return (
<>
<Header/>
<div className="analytics-section">
      <div className="sivo" > </div>


      <div className="section-container">
      <div
            className="section-header"
            style={{ flexDirection: "column", alignItems: "normal" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                padding: "2em 0",
                cursor: "pointer"
              }}
              onClick={() => props.history.replace('pregled')}
            >
              <img src={backButton}></img>
              <h3
                style={{ weight: "400", marginLeft: "20px", fontSize: "24px" }}
              >
                Pojdi nazaj
              </h3>
            </div>
          </div>
        {renderRatingTable()}</div>
    </div>
</>
  );
}
