import React, { useState, useEffect, useContext } from "react";
import nemateAktivnosti from "../../assets/panel/nemateAktivnosti.svg";
import ActivityTable from "./partials/activityTable/ActivityTable";
import axios from "axios"
import moment from "moment"
import { store } from '../../Store';
import Header from './partials/header/Header'
import backButton from "../../assets/panel/backButton.svg";
export default function Overview({ ...props }) {
  const [dataView, setDataView] = React.useState('prihodnji');
  const { state, dispatch } = useContext(store);

  console.log(state)

  const [nextWeekSessions, setNextWeekSessions] = useState([]);
  const [todaysSessions, setTodaysSessions] = useState([]);
  const [dayBeforeSessions, setDayBeforeSessions] = useState([]);
  const [monthAgoSessions, setMonthAgoSessions] = useState([]);
  const [todaysSessionsThatPassed, setTodaysSessionsThatPassed] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState([]);

  const currentDateEndThAgo = moment().subtract(30, "minutes").toISOString();

  const currentDateEnd = moment().set({ hour: 25, minute: 0, second: 0, millisecond: 0 }).toISOString();
  const currentDateStart = moment().set({ hour: 3, minute: 0, second: 0, millisecond: 0 }).toISOString();

  const dayBeforeCurrentDateStart = moment().subtract(1, 'day').set({ hour: 3, minute: 0, second: 0, millisecond: 0 }).toISOString();
  const twoDaysAhead = moment().add(3, 'day').set({ hour: 1, minute: 0, second: 0, millisecond: 0 }).toISOString();
  const startOfMonth = moment().clone().startOf('month').set({ month: selectedMonth, hour: 3, minute: 0, second: 0, millisecond: 0 }).toISOString();
  const endOfMonth = moment().clone().endOf('month').set({ month: selectedMonth, hour: 3, minute: 0, second: 0, millisecond: 0 }).toISOString();
  const currentTime = new Date().toISOString();
  const startofMonthAletered =  moment().clone().startOf('year').subtract(11, 'year').set({hour:3,minute:0,second:0,millisecond:0}).toISOString(); 


  console.table("CurrentDateStart", currentDateStart, currentDateEndThAgo)

  useEffect(async () => {
    changeMonth(22)
    setNextWeekSessions(await getSessions(state.data.user._id, currentDateEnd, twoDaysAhead, 100));
    setTodaysSessions(await getSessions(state.data.user._id, currentDateEndThAgo, currentDateEnd, 100));
    setTodaysSessionsThatPassed(await getSessions(state.data.user._id, currentDateStart, currentDateEndThAgo, 100));
    setDayBeforeSessions(await getSessions(state.data.user._id, dayBeforeCurrentDateStart, currentDateStart, 100));
    setMonthAgoSessions(await getSessions(state.data.user._id, startofMonthAletered, currentDateEndThAgo, 100));
  }, [])


  const getSessions = async (doctorId, startDate, endDate, limit) => {
    return axios.get(`${process.env.REACT_APP_API_URL}sessions/analyticsessions/${startDate}/${endDate}/${limit}/${process.env.REACT_APP_PROJECT}`, {
      headers: {
        'x-api-key': `${process.env.REACT_APP_API_LOCAL_KEY}`
      }
    }).then((response) => {
      console.table(startDate, endDate, response.data)
      return response.data;
    });
  }


  


  const renderNoActivity = () => {
    return (
      <>
        <div className="section-header">
          <h3>Aktivnosti</h3>
          <div className="button-grey" onClick={() => props.history.replace('nastavitve')}>Nastavitve prostih terminov</div>
        </div>
        <hr />
        <div
          className="section-content"
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
            backgroundImage: `url('${nemateAktivnosti}')`,
            backgroundPosition: "right",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div>
            <h5 style={{ fontSize: "22px", lineHeight: "35px" }}>
              Še nemate nobenih aktivnosti.<br></br>
              Povabite pacienta na posvet.
            </h5>
            <div className="button-red">Povabi</div>
          </div>
        </div>
      </>
    );
  };


  const renderUpcomingEvents = () => {
    return (
      <>
        <h5>
          <strong>Danes</strong> - {moment(currentDateStart).format('dddd, D. MMMM, YYYY')}
        </h5>
        <div className="grey-container">
          <ActivityTable props={props} editable={false} data={todaysSessions} />
        </div>
        <hr style={{ margin: "3em 0" }} />
        <h5>
          <strong>Naslednji teden</strong>
        </h5>
        <div className="grey-container" style={{
          marginBottom: "2em"
        }}>
          <ActivityTable props={props} editable={false} data={nextWeekSessions} />
        </div>
      </>
    );
  };

  const changeMonth = async (month) => {
    if(month == 22) {
      const endOfMonthAltered = moment().set({hour:25,minute:0,second:0,millisecond:0}).toISOString();
      const startofMonthAletered =  moment().clone().startOf('year').subtract(11, 'year').set({hour:3,minute:0,second:0,millisecond:0}).toISOString(); 
      console.log(monthAgoSessions)
      setMonthAgoSessions([]);
      setSelectedMonth(month)
      await axios.get(`${process.env.REACT_APP_API_URL}sessions/analyticsessions/${startofMonthAletered}/${endOfMonthAltered}/100/${process.env.REACT_APP_PROJECT}`).then((response) => {
        setMonthAgoSessions(response.data);
      });
      console.log(monthAgoSessions)      
    } else {
      const endOfMonthAltered = moment().clone().endOf('month').set({ month: month, hour: 3, minute: 0, second: 0, millisecond: 0 }).toISOString();
      const startofMonthAletered =  moment().clone().startOf('month').set({ month: month, hour: 3, minute: 0, second: 0, millisecond: 0 }).toISOString(); 
      console.log(monthAgoSessions)
      setMonthAgoSessions([]);
      setSelectedMonth(month)
      await axios.get(`${process.env.REACT_APP_API_URL}sessions/analyticsessions/${startofMonthAletered}/${endOfMonthAltered}/100/${process.env.REACT_APP_PROJECT}`).then((response) => {
        setMonthAgoSessions(response.data);
      });
      console.log(monthAgoSessions)
    }
  }

  const renderPastEvents = () => {
    return (
      <>
        <h5>
          <strong>Včeraj</strong> - {moment(dayBeforeCurrentDateStart).format('dddd, D. MMMM, YYYY')}
        </h5>
        <div className="grey-container">
          <ActivityTable props={props} editable={false} data={dayBeforeSessions} call={true} />
        </div>
        <hr style={{ margin: "3em 0" }} />
        <h5>
          <strong>Prejšnji</strong> - {selectedMonth == 22 ? "do" : "za"} {moment(startOfMonth).format('MMMM, YYYY')}
        </h5>
        <div className="row-container" style={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(15%, 3fr))" }}>
        <div className="greyPlate" style={(selectedMonth == 22) ? {color: "#5cbdb2", gridRowStart: "1" } :  {color: "inherit", gridRowStart: "1" }} onClick={async () => {
            changeMonth(22)
          }}>Vsi posveti</div>
          <div className="greyPlate" style={(selectedMonth == 0) ? {color: "#5cbdb2", gridRowStart: "2"} :  {color: "inherit", gridRowStart: "2" }} onClick={async () => {
            changeMonth(0)
          }}>Januar</div>
          <div className="greyPlate" style={(selectedMonth == 1) ? {color: "#5cbdb2", gridRowStart: "2"} :  {color: "inherit", gridRowStart: "2" }} onClick={async() => {
            changeMonth(1)
          }}>Februar</div>
          <div className="greyPlate" style={(selectedMonth == 2) ? {color: "#5cbdb2", gridRowStart: "2"} :  {color: "inherit", gridRowStart: "2" }} onClick={async() => {
            changeMonth(2)
          }}>Marec</div>
          <div className="greyPlate" style={(selectedMonth == 3) ? {color: "#5cbdb2", gridRowStart: "2"} :  {color: "inherit", gridRowStart: "2" }} onClick={async() => {
            changeMonth(3)
          }}>April</div>
          <div className="greyPlate" style={(selectedMonth == 4) ? {color: "#5cbdb2", gridRowStart: "2"} :  {color: "inherit", gridRowStart: "2" }} onClick={async() => {
            changeMonth(4)
          }}>Maj</div>
          <div className="greyPlate" style={(selectedMonth == 5) ? {color: "#5cbdb2", gridRowStart: "2"} :  {color: "inherit", gridRowStart: "2" }} onClick={async() => {
            changeMonth(5)
          }}>Junij</div>
          <div className="greyPlate" style={(selectedMonth == 6) ? {color: "#5cbdb2", gridRowStart: "3"} :  {color: "inherit", gridRowStart: "3" }} onClick={async() => {
            changeMonth(6)
          }}>Julij</div>
          <div className="greyPlate" style={(selectedMonth == 7) ? {color: "#5cbdb2", gridRowStart: "3"} :  {color: "inherit", gridRowStart: "3" }} onClick={async() => {
            changeMonth(7)
          }}>Avgust</div>
          <div className="greyPlate" style={(selectedMonth == 8) ? {color: "#5cbdb2", gridRowStart: "3"} :  {color: "inherit", gridRowStart: "3" }} onClick={async() => {
            changeMonth(8)
          }}>September</div>
          <div className="greyPlate" style={(selectedMonth == 9) ? {color: "#5cbdb2", gridRowStart: "3"} :  {color: "inherit", gridRowStart: "3" }} onClick={async() => {
            changeMonth(9)
          }}>Oktober</div>
          <div className="greyPlate" style={(selectedMonth == 10) ? {color: "#5cbdb2", gridRowStart: "3"} :  {color: "inherit", gridRowStart: "3"}} onClick={async() => {
            changeMonth(10)
          }}>November</div>
          <div className="greyPlate" style={(selectedMonth == 11) ? {color: "#5cbdb2", gridRowStart: "3"} :  {color: "inherit", gridRowStart: "3"}} onClick={async() => {
            changeMonth(11)
          }}>December</div>
        </div>
        <div className="grey-container"
          style={{ marginBottom: "2em" }}>
          <ActivityTable props={props} editable={false} data={monthAgoSessions} call={true} limited={false} />
        </div>
      </>
    );
  };

  const renderActivity = () => {
    return (
      <>
        <div
          className="section-header"
          style={{
            marginBottom: "2em",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignContent: "normal",
            alignItems: "normal",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h4 style={{ fontSize: "34px", color: "#575757", fontWeight: "bold", marginBottom: "1em" }}>Vsi posveti</h4>
            </div>
            {/* <div className="button-grey" onClick={() => props.history.replace('nastavitve')}>Nastavitve prostih terminov</div> */}
          </div>
          <div
            className="filters"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <h5 onClick={() => setDataView('prihodnji')} style={(dataView == "prihodnji") ? { color: "#C4385B", textDecoration: "underline" } : {}}>Prihodnji</h5>
            <h5 onClick={() => setDataView('pretekli')} style={(dataView == "pretekli") ? { color: "#C4385B", textDecoration: "underline" } : {}}>Pretekli</h5>
          </div>
        </div>
        <hr />
        <div
          className="section-content"
          style={{ height: "auto", color: "#707070" }}
        >
          {(dataView == "prihodnji") ? renderUpcomingEvents() : renderPastEvents()}
        </div>
      </>
    );
  };

  return (
    <>
      <Header />
      <div className="analytics-section">
        <div className="sivo" > </div>


        <div className="section-container">
          <div
            className="section-header"
            style={{ flexDirection: "column", alignItems: "normal" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                padding: "2em 0",
                cursor: "pointer"
              }}
              onClick={() => props.history.replace('pregled')}
            >
              <img src={backButton}></img>
              <h3
                style={{ weight: "400", marginLeft: "20px", fontSize: "24px" }}
              >
                Pojdi nazaj
              </h3>
            </div>
          </div>
          {renderActivity()}</div>
      </div>
    </>
  );
}
