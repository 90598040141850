import React, { useState } from "react";

import moment from "moment";
import TextField from "@material-ui/core/TextField";
import specializations from "../../list.json";
import axios from 'axios';
import Button from "@material-ui/core/Button";
import Header from './partials/header/Header'
import backButton from "../../assets/panel/backButton.svg";
import homeButton from "../../assets/panel/homeButton.svg";

export default function PacientCard({...props}) {


  const [state, setState]= React.useState({
    oppinionForInsurance: "",
    nextStep: undefined,
    diagnosis: ""
  });

  const {
    oppinionForInsurance,
    nextStep,
    diagnosis
  }=state;

  console.log("Ovaj je za Pacient Card", state);
  const [userDataView, setuserDataView] = React.useState('vprasalnik');
  const userData = props.location.state.pacient;
  const doctorData = props.location.state.doctor;
  console.log("Pacient Card", props);


  const renderQuestionaryResults = (userData) => {
    return ( 
    <div className="grey-container pacientCard">
    <h5>Termin pacienta</h5>
    <div class="pacientCardRow">
      <div className="key">
      Ime zdravnika
      </div>
      <div className="value">
      {doctorData.firstName} {doctorData.lastName}
      </div>
    </div>
    <div class="pacientCardRow">
      <div className="key">
      Čas
      </div>
      <div className="value">
      {moment(userData.sessionStartTime).hours().toString().length <= 1 ? "0" + moment(userData.sessionStartTime).hours().toString() : moment(userData.sessionStartTime).hours().toString()}
      :{moment(userData.sessionStartTime).minutes().toString().length <= 1 ? moment(userData.sessionStartTime).minutes().toString() + "0" :  moment(userData.sessionStartTime).minutes().toString()}h
      </div>
    </div>
    <h5>Podatki pacienta</h5>
    <div class="pacientCardRow">
      <div className="key">
      Ime:
      </div>
      <div className="value">
      {userData.firstName}
      </div>
    </div>
    <div class="pacientCardRow">
      <div className="key">
      Priimek:
      </div>
      <div className="value">
      {userData.lastName}
      </div>
    </div>
    <div class="pacientCardRow">
      <div className="key">
      Telefonska številka:
      </div>
      <div className="value">
      {userData.phone}
      </div>
    </div>
    <div class="pacientCardRow">
      <div className="key">
      Davčna številka zavarovanca:
      </div>
      <div className="value">
      {userData.pacientId}
      </div>
    </div>
    <h5>Podrobnosti</h5>
    <div class="pacientCardRow">
      <div className="key">
      Zdravnik je zavarovanca napotil na:
      </div>
      <div className="value">
      {userData.nextStep}
      </div>
    </div>

          <div class="pacientCardRowReversed" style={{ margin: "none" }}>
          <div className="key">
    Mnenje zdravnika za zavarovalnico:
    </div>
            <TextField
              className="value"
              id="full-width"
              placeholder="Vaša mnenje"
              multiline
              style={{color: "#19191A", padding: "2% 10% 2% 2%"}}
              fullWidth
              margin="normal"
              disabled
              value={userData.oppinionForInsurance}
            />
          </div>

  </div>)
  }
  
  
  const renderAnamnesis = (userData,props) => {
    return (
      <>
        <h5 style={{ fontWeight: "bold" }}>Vaša mnenje</h5>
        <div className="grey-container">
          <div class="pacientCardRowReversed" style={{ margin: "none" }}>
            <TextField
              className="value"
              id="outlined-full-width"
              style={{ margin: 8 }}
              placeholder="Vaša mnenje"
              multiline
              fullWidth
              margin="normal"
              disabled={!props.location.state.editable}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={userData.diagnosis}
              onChange={(e) =>
                setState({ ...state, diagnosis: e.target.value })
              }
            />
          </div>
        </div>
        <hr style={{ margin: "3em 0 1em 0" }} />
        <h5 style={{ fontWeight: "bold" }}>Napotitev k specialistu</h5>
        <div className="grey-container">
          <div class="pacientCardRowReversed" style={{ margin: "none" }}>
            {/* <TextField className="value"
                    id="outlined-full-width"
                    style={{ margin: 8,}}
                    placeholder="Napotitev k specialisti"
                    multiline
                    fullWidth
                    margin="normal"
                   disabled={  !props.location.state.editable   }
                    
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    value={userData.nextStep}
                  /> */}

            <input
              id="country"
              name="country"
              required
              list="country-list"
              className="value"
              id="outlined-full-width"
              style={{ margin: 8 }}
              placeholder="Napotitev k specialistu"
              multiline
              fullWidth
              margin="normal"
              disabled={!props.location.state.editable}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={userData.nextStep}
              onChange={ (e) => {
                const verify = specializations.filter((item) => item == e.target.value)
                if(e.target.value.length < 1){
                  setState({ ...state, nextStep: undefined})
                } else {
                  if(verify == e.target.value) {
                    setState({ ...state, nextStep: e.target.value})} else {
                     setState({ ...state, nextStep: undefined})
                    }
                }
               } }
                              title="Please select a country"
              style={{ width: "100%", padding: "0.5em" }}
            />
            <userDatalist id="country-list" style={{ width: "100%" }}>
              {specializations.map((item) => (
                <option value={item}>{item}</option>
              ))}
            </userDatalist>
          </div>
        </div>
        <hr style={{ margin: "3em 0 1em 0" }} />
        <h5 style={{ fontWeight: "bold" }}>Mnenje za zavarovalnico</h5>
        <div className="grey-container">
          <div className="pacientCardRowReversed" style={{ margin: "none" }}>
            <TextField
              className="value"
              id="outlined-full-width"
              style={{ margin: 8 }}
              placeholder="Mnenje za zavarovalnico"
              multiline
              fullWidth
              margin="normal"
              disabled={!props.location.state.editable}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={userData.oppinionForInsurance}
              onChange={(e) =>
                setState({ ...state, oppinionForInsurance: e.target.value })
              }
            />
          </div>
        </div>

        {!props.location.state.editable ? (
          <></>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              margin: "2em 0",
            }}
          >
            <Button
              className={(diagnosis.length < 1 || nextStep == undefined) ? "button-grey" : "button-red"}
              style={
                (diagnosis.length < 1 || nextStep == undefined)
                  ? { color: "#000", background: "#efefef", marginTop: "0" }
                  : { marginTop: "0", background: "#5cc8bd" }
              }
              disabled={
                diagnosis.length < 1 || nextStep == undefined ? true : false
              }
              onClick={() => sendSurvey(state, props)}
            >
              Oddajte
            </Button>
            <Button className="button-grey" style={{ marginLeft: "1em" }}>
              Prekliči
            </Button>
          </div>
        )}
      </>
    );
  }


  const sendSurvey = (state, props) => {
    console.log('Update survey with survey id', props);
    axios.get(`${process.env.REACT_APP_API_URL}sessions/${userData._id}`, {
      headers: {
        'x-api-key': `${process.env.REACT_APP_API_LOCAL_KEY}`
      }
    }).then((response) => {
      console.log(response);
      axios.patch(`${process.env.REACT_APP_API_URL}sessions/${userData._id}`, {
        id: userData._id,
        diagnosis: diagnosis,
        nextStep: nextStep,
        oppinionForInsurance: oppinionForInsurance,
      // callStatus: "Canceled"
      }, {
        headers: {
          'x-api-key': `${process.env.REACT_APP_API_LOCAL_KEY}`
        }
      })
      console.log(props)
      props.history.replace('pacienti')
    })};

  return (
    <>
      <Header logout={false} />
      <div className="analytics-section">
        <div className="section-container" style={{marginBottom: "2em"}}>
          <div
            className="section-header"
            style={{ flexDirection: "column", alignItems: "normal" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                padding: "2em 0",
                cursor: "pointer"
              }}
            >
              <div style={{display: "flex", flexDirection: "row", alignContent: "center"}}  onClick={() => props.history.replace('pacienti')}>
              <img src={backButton}></img>
              <h3
                style={{ weight: "400", marginLeft: "20px", fontSize: "24px" }}
              >
                Pojdi nazaj
              </h3>
              </div>
              <div style={{display: "flex", flexDirection: "row", alignContent: "center", marginLeft: "2em"}} onClick={() => props.history.replace('pregled')}>
              <img src={homeButton}></img>
              <h3
                style={{ weight: "400", marginLeft: "20px", fontSize: "24px" }}
              >
                Domov
              </h3>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                marginTop: "0.5em",
                justifyContent: "space-between",
                alignItems:"center",
              }}
            >
              <h3>Zavarovanec: {userData.firstName} {userData.lastName}</h3>
              <div
                className="filters"
                style={{ display: "flex", flexDirection: "row" }}
              >
                {/* <h5 onClick={() => setuserDataView('vprasalnik')} style={(userDataView == "vprasalnik") ? {color: "#C4385B"} : {}}>Vprašalnik</h5>
                <h5 onClick={() => setuserDataView('mnenje')} style={(userDataView == "mnenje") ? {color: "#C4385B"} : {}}>Mnenje</h5> */}
              </div>
            </div>
          </div>
          <hr />
          <div
            className="section-content" style={{height: "auto"}}
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          > 
            {(userDataView == "vprasalnik") ? renderQuestionaryResults(userData) : renderAnamnesis(userData,props)}
          </div>
        </div>
      </div>
    </>
  );
}
